import MobileMenu from "./Mobilemenu";

export default function Header() {
  
  return (
    <>
      <header className="header">
          <div className="logo-background">
            <img
              className="logo-background-child"
              loading="lazy"
              alt=""
              src="/img/group-112@2x.png"
            />

            <div className="brand-name">
              <img
                className="canbizz-icon"
                loading="lazy"
                alt=""
                src="/img/canbizz.svg"
              />
            </div>
          </div>
          <div className="portfolio">Portfolio</div>
          <div className="navigation">
            <div className="nav-links">
              <nav className="copyright">
                <a href="#" className="features">
                  Features
                </a>
                <a href="#" className="about">
                  About
                </a>
                <a href="#" className="pricing">
                  Pricing
                </a>
                <a href="#" className="customer-reviews">
                  Customer Reviews
                </a>
              </nav>
              <div className="nav-link-separator"></div>
            </div>
          </div>
          <div className="call-to-action">
            <button className="demo-button-container">
              <a href="#" className="get-a-free">
                Get a Free Demo
              </a>
            </button>
            <button
              className="login-button-container"
              id="loginButtonContainer"
            >
              <a href="#" className="login">
                Login
              </a>
            </button>
          </div>
          <div className="mobileDevice">
            <MobileMenu />
          </div>
        </header>
    </>
  );
}
