export default function BusinessModelCanvas() {

  return (
    <>
      <section className="hero-content">
        <div className="hero-title-container-parent">
          <div className="hero-title-container">
            <div className="hero-title">
              <div className="hero-title-inner">
                <div className="canbiz-a-business-model-canv-wrapper">
                  <div className="canbiz-a">
                    Canbiz - A Business Model Canvas Portal
                  </div>
                </div>
              </div>
              <h1 className="access-our-business">
                Access Our Business Model and Transform Your Vision into
                Reality
              </h1>
              <div className="hero-button">
                <button
                  className="hero-button-container"
                  id="heroButtonContainer"
                >
                  <div className="get-started">Get Started</div>
                </button>
              </div>
            </div>
          </div>
          <div className="dashboard-preview">
            <div className="dashboard-preview-child"></div>
            <div className="canbiz-dashboard-list-view">
              <div className="header-bg-parent">
                <div className="header-bg"></div>
                <div className="dashboard-title-container">
                  <img
                    className="dashboard-title-container-child"
                    loading="lazy"
                    alt=""
                    src="/img/group-112-1@2x.png"
                  />

                  <div className="dashboard-brand">
                    <img
                      className="canbizz-icon1"
                      loading="lazy"
                      alt=""
                      src="/img/canbizz-1.svg"
                    />
                  </div>
                </div>
                <div className="portfolio1">Portfolio</div>
                <div className="dashboard-content">
                  <div className="dashboard-card">
                    <div className="dashboard">Dashboard</div>
                    <div className="dashboard-card-type">
                      <div className="business-model-canvas">
                        Business Model Canvas
                      </div>
                      <img
                        className="empty-icon"
                        alt=""
                        src="/img/empty.svg"
                      />
                    </div>
                    <div className="test">Test</div>
                  </div>
                </div>
                <div className="dashboard-footer">
                  <div className="dashboard-user">
                    <img
                      className="empty-icon1"
                      alt=""
                      src="/img/frame-1.svg"
                    />

                    <div className="dashboard-credits">
                      <div className="credits-05">Credits 05</div>
                    </div>
                  </div>
                  <div className="dashboard-avatar">
                    <img
                      className="empty-icon2"
                      alt=""
                      src="/img/frame-2.svg"
                    />
                  </div>
                  <img
                    className="avtar-icon"
                    alt=""
                    src="/img/avtar@2x.png"
                  />
                </div>
              </div>
              <div className="canvas-container-wrapper">
                <div className="canvas-container">
                  <div className="canvas-header">
                    <div className="canvas-title-container">
                      <div className="canvas-title">
                        <div className="canvas-name">
                          <div className="business-model-canvas1">
                            Business Model Canvas
                          </div>
                        </div>
                        <div className="section-title">/</div>
                        <div className="section-title-wrapper">
                          <a className="section-title1">Sports Wear</a>
                        </div>
                      </div>
                    </div>
                    <div className="canvas-content">
                      <div className="progress-bar-container">
                        <div className="progress-bar">
                          <div className="progress-value-container">
                            <div className="progress-percentage">
                              Overall Progress
                            </div>
                          </div>
                          <input
                            className="remaining-progress-container"
                            placeholder="76%"
                            type="text"
                          />
                        </div>
                        <div className="progress-bar1">
                          <div className="current-progress"></div>
                        </div>
                      </div>
                      <div className="canvas-actions">
                        <div className="new-canvas-button">
                          <div className="new-canvas-button-container">
                            <img
                              className="empty-icon3"
                              alt=""
                              src="/img/frame-3.svg"
                            />
                          </div>
                          <div className="create-new-canvas">
                            Create New Canvas
                          </div>
                          <div className="more-actions">
                            <div className="more-actions-container">
                              <img
                                className="empty-icon4"
                                alt=""
                                src="/img/frame-4.svg"
                              />

                              <div className="more-actions-button">
                                <div className="empty">5</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business-model">
                    <div className="segments-and-relations">
                      <div className="sub-section-title-parent">
                        <div className="sub-section-title">Desirability</div>
                        <img
                          className="frame-icon"
                          alt=""
                          src="/img/frame-5.svg"
                        />
                      </div>
                      <div className="segments-and-relations-items">
                        <div className="segments-and-relations-header">
                          <div className="segments-and-relations-title">
                            <div className="segments-and-relations-content">
                              <div className="segments-and-relations-labels">
                                <img
                                  className="segment-label-icon"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-6.svg"
                                />

                                <div className="segment-name">
                                  <div className="customer-segments">
                                    Customer Segments
                                  </div>
                                  <div className="segment-placeholder">
                                    (3)
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="segment-add">
                              <div className="create-new">+ Create New</div>
                              <div className="segment-divider"></div>
                            </div>
                            <img
                              className="frame-icon1"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list">
                            <div className="fitness-fashion-seekers-wrapper">
                              <div className="fitness-fashion-seekers">
                                Fitness Fashion Seekers
                              </div>
                            </div>
                            <div className="outdoor-adventure-aficionados-wrapper">
                              <div className="outdoor-adventure-aficionados">
                                Outdoor Adventure Aficionados
                              </div>
                            </div>
                            <div className="team-spirit-supporters-wrapper">
                              <div className="team-spirit-supporters">
                                Team Spirit Supporters
                              </div>
                            </div>
                            <div className="urban-fitness-trendsetters-wrapper">
                              <div className="urban-fitness-trendsetters">
                                Urban Fitness Trendsetters
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="propositions">
                          <div className="proposition-items">
                            <div className="proposition-header">
                              <div className="proposition-title">
                                <img
                                  className="proposition-type-icon"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-7.svg"
                                />

                                <div className="proposition-name">
                                  <div className="value-proposition">
                                    Value Proposition
                                  </div>
                                  <div className="proposition-placeholder">
                                    (1)
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="proposition-add">
                              <div className="create-new1">+ Create New</div>
                              <div className="proposition-divider"></div>
                            </div>
                            <img
                              className="frame-icon2"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list1">
                            <div className="core-product-is-marty-the-robo-wrapper">
                              <div className="core-product-is">
                                Core product is Marty the Robot, a smart toy
                                designed to teach children about coding,
                                electronics and engineering. Marty is real
                                robot with arms and legs that users can
                                customize and programme to perform basic
                                functions, such as walking, shaking hands and
                                dancing.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="channel-list-parent">
                          <div className="channel-list">
                            <div className="channel-item">
                              <div className="channel-content">
                                <img
                                  className="channel-icon"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-8.svg"
                                />

                                <div className="channel-description">
                                  <div className="channels">Channels</div>
                                  <div className="channel-placeholder">
                                    (1)
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="add-channel">
                              <div className="create-new2">+ Create New</div>
                              <div className="add-channel-divider"></div>
                            </div>
                            <img
                              className="frame-icon3"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list2">
                            <div className="website-channel">
                              <div className="website">Website</div>
                              <div className="develop-a-professional">
                                Develop a professional and user-friendly
                                website for the sports campaign. The website
                                should serve as a central hub for information,
                                registration, updates, and resources related
                                to the campaign. Include features such as
                                event calendars, registration forms, FAQs, and
                                contact information.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="segments-and-relations-items1">
                        <div className="frame-parent">
                          <div className="frame-wrapper">
                            <div className="frame-group">
                              <img
                                className="frame-icon4"
                                loading="lazy"
                                alt=""
                                src="/img/frame-9.svg"
                              />

                              <div className="relationship-label">
                                <div className="customer-relationship">
                                  Customer Relationship
                                </div>
                                <div className="relationship-placeholder">
                                  (1)
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="create-new-parent">
                            <div className="create-new3">+ Create New</div>
                            <div className="frame-child"></div>
                          </div>
                          <img
                            className="frame-icon5"
                            alt=""
                            src="/img/frame.svg"
                          />
                        </div>
                        <div className="no-data">
                          <div className="no-data-inner">
                            <div className="marty-the-robot-brings-coding-wrapper">
                              <div className="marty-the-robot">
                                Marty the Robot brings coding to life,
                                providing students with an inspiring,
                                practical and fun introduction to digital
                                skills for study, work and life. Super easy
                                for teachers to use with a full range of
                                lesson plans and activities available on the
                                Marty website.
                              </div>
                            </div>
                          </div>
                          <div className="frame-container">
                            <img
                              className="frame-icon6"
                              alt=""
                              src="/img/frame-10.svg"
                            />

                            <div className="no-data-please">
                              No Data. Please Create New
                            </div>
                          </div>
                        </div>
                        <div className="list3">
                          <div className="get">
                            <div className="get1">Get</div>
                            <div className="stocking-in-major-supermarkets-parent">
                              <div className="stocking-in-major">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-group">
                              <div className="stocking-in-major1">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-container">
                              <div className="stocking-in-major2">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                          </div>
                          <div className="keep">
                            <div className="keep1">Keep</div>
                            <div className="stories-on-social-media-parent">
                              <div className="stories-on-social">
                                Stories on Social Media
                              </div>
                              <div className="being-stocked-on1">
                                Sharing stories about the impact that
                                Cafedirect is having on farmers makes
                                customers feel good and positive about their
                                purchase.
                              </div>
                            </div>
                            <div className="frame-div">
                              <div className="stocking-in-major3">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-parent1">
                              <div className="stocking-in-major4">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                          </div>
                          <div className="grow">
                            <div className="grow1">Grow</div>
                            <div className="discount-codes-parent">
                              <div className="discount-codes">
                                Discount codes
                              </div>
                              <div className="being-stocked-on1">
                                Customers get discounts on orders if they add
                                a code received from previous orders.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-parent2">
                              <div className="stocking-in-major5">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-parent3">
                              <div className="stocking-in-major6">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                          </div>
                          <div className="referrals">
                            <div className="referrals1">Referrals</div>
                            <div className="referrals-description">
                              <div className="partnerships-with-businesses">
                                Partnerships with businesses
                              </div>
                              <div className="being-stocked-on1">
                                Businesses buy directly as they want to show
                                their commitment to ethical business and
                                buying Cafedirect coffee is a good way to
                                demonstrate this.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-parent4">
                              <div className="stocking-in-major7">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                            <div className="stocking-in-major-supermarkets-parent5">
                              <div className="stocking-in-major8">
                                Stocking in major supermarkets
                              </div>
                              <div className="being-stocked-on1">
                                Being stocked on the shelves of major
                                supermarkets gives customers visibility of the
                                brand when they go to purchase coffee.
                              </div>
                            </div>
                          </div>
                          <div className="outdoor-adventure-aficionados-container">
                            <div className="outdoor-adventure-aficionados1">
                              Outdoor Adventure Aficionados
                            </div>
                          </div>
                          <div className="team-spirit-supporters-container">
                            <div className="team-spirit-supporters1">
                              Team Spirit Supporters
                            </div>
                          </div>
                          <div className="urban-fitness-trendsetters-container">
                            <div className="urban-fitness-trendsetters1">
                              Urban Fitness Trendsetters
                            </div>
                          </div>
                          <div className="urban-fitness-trendsetters-frame">
                            <div className="urban-fitness-trendsetters2">
                              Urban Fitness Trendsetters
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-parent1">
                      <div className="sub-section-title-group">
                        <div className="sub-section-title1">Feasibility</div>
                        <img
                          className="frame-icon7"
                          alt=""
                          src="/img/frame-11.svg"
                        />
                      </div>
                      <div className="key-activities">
                        <div className="key-activities-header">
                          <div className="key-activities-title">
                            <div className="key-activities-name">
                              <div className="key-activities-label">
                                <img
                                  className="empty-icon5"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-27.svg"
                                />

                                <div className="key-activities-value">
                                  <div className="key-activities1">
                                    Key Activities
                                  </div>
                                  <div className="separator">(3)</div>
                                </div>
                              </div>
                            </div>
                            <div className="key-activities-actions">
                              <div className="create-new4">+ Create New</div>
                              <div className="divider"></div>
                            </div>
                            <img
                              className="frame-icon8"
                              alt=""
                              src="/img/frame-10.svg"
                            />
                          </div>
                          <div className="list4">
                            <div className="step-1-parent">
                              <div className="step-1">Step 1</div>
                              <div className="collect-discarded-flip-flops">
                                Collect discarded flip-flops along beaches and
                                waterways
                              </div>
                            </div>
                            <div className="step-2-parent">
                              <div className="step-2">Step 2</div>
                              <div className="create-designs-for">
                                Create designs for art made from flip-flops
                              </div>
                            </div>
                            <div className="step-3-parent">
                              <div className="step-3">Step 3</div>
                              <div className="clean-and-sort">
                                Clean and sort the flip-flops
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="resources">
                          <div className="resource-items">
                            <div className="resource-header">
                              <div className="resource-title">
                                <img
                                  className="resource-type-icon"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-27.svg"
                                />

                                <div className="resource-label">
                                  <div className="key-resource">
                                    Key Resource
                                  </div>
                                  <div className="resource-separator">
                                    (2)
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="resource-add">
                              <div className="create-new5">+ Create New</div>
                              <div className="resource-divider"></div>
                            </div>
                            <img
                              className="frame-icon9"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list5">
                            <div className="a-team-with-expertise-in-relev-wrapper">
                              <div className="a-team-with">
                                A team with expertise in relevant technologies
                                to design Marty
                              </div>
                            </div>
                            <div className="a-warehouse-to-store-finished-wrapper">
                              <div className="a-warehouse-to">
                                A warehouse to store finished products
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="partners">
                          <div className="partner-items">
                            <div className="partner-header">
                              <div className="partner-title">
                                <img
                                  className="partner-type-icon"
                                  alt=""
                                  src="/img/frame-27.svg"
                                />

                                <div className="partner-label">
                                  <div className="key-partners">
                                    Key Partners
                                  </div>
                                  <div className="partner-separator">(1)</div>
                                </div>
                              </div>
                            </div>
                            <div className="partner-add">
                              <div className="create-new6">+ Create New</div>
                              <div className="partner-divider"></div>
                            </div>
                            <img
                              className="frame-icon10"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list6">
                            <div className="high-quality-manufacturers-to-wrapper">
                              <div className="high-quality-manufacturers">
                                High quality manufacturers to produce Marty
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-parent2">
                      <div className="sub-section-title-container">
                        <div className="sub-section-title2">Viability</div>
                        <img
                          className="frame-icon11"
                          alt=""
                          src="/img/frame-15.svg"
                        />
                      </div>
                      <div className="frame-parent3">
                        <div className="frame-parent4">
                          <div className="frame-parent5">
                            <div className="frame-wrapper1">
                              <div className="frame-parent6">
                                <img
                                  className="frame-icon12"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-27.svg"
                                />

                                <div className="cost-parent">
                                  <div className="cost">Cost</div>
                                  <div className="div">(2)</div>
                                </div>
                              </div>
                            </div>
                            <div className="create-new-group">
                              <div className="create-new7">+ Create New</div>
                              <div className="frame-item"></div>
                            </div>
                            <img
                              className="frame-icon13"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list7">
                            <div className="annual-fixed-cost-parent">
                              <div className="annual-fixed-cost">
                                Annual Fixed Cost
                              </div>
                              <div className="div1">111,220</div>
                            </div>
                            <div className="annual-variable-cost-parent">
                              <div className="annual-variable-cost">
                                Annual Variable Cost
                              </div>
                              <div className="div2">0</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-parent7">
                          <div className="frame-parent8">
                            <div className="frame-wrapper2">
                              <div className="frame-parent9">
                                <img
                                  className="frame-icon14"
                                  loading="lazy"
                                  alt=""
                                  src="/img/frame-27.svg"
                                />

                                <div className="revenue-parent">
                                  <div className="revenue">Revenue</div>
                                  <div className="div3">(2)</div>
                                </div>
                              </div>
                            </div>
                            <div className="create-new-container">
                              <div className="create-new8">+ Create New</div>
                              <div className="frame-inner"></div>
                            </div>
                            <img
                              className="frame-icon15"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list8">
                            <div className="revenue-model-parent">
                              <input
                                className="revenue-model"
                                placeholder="Revenue Model"
                                type="text"
                              />

                              <div className="div4">-</div>
                            </div>
                            <div className="annual-units-sold-parent">
                              <div className="annual-units-sold">
                                Annual Units Sold
                              </div>
                              <div className="div5">0</div>
                            </div>
                            <div className="annual-revenue-parent">
                              <div className="annual-revenue">
                                Annual Revenue
                              </div>
                              <div className="div6">0</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-parent10">
                          <div className="frame-parent11">
                            <div className="frame-wrapper3">
                              <div className="frame-parent12">
                                <img
                                  className="frame-icon16"
                                  alt=""
                                  src="/img/frame-27.svg"
                                />

                                <div className="sustainabilty-parent">
                                  <div className="sustainabilty">
                                    Sustainabilty
                                  </div>
                                  <div className="div7">(2)</div>
                                </div>
                              </div>
                            </div>
                            <div className="create-new-parent1">
                              <a className="create-new9">+ Create New</a>
                              <div className="line-div"></div>
                            </div>
                            <img
                              className="frame-icon17"
                              alt=""
                              src="/img/frame.svg"
                            />
                          </div>
                          <div className="list9">
                            <div className="quality-education-parent">
                              <div className="quality-education">
                                Quality Education
                              </div>
                              <div className="young-people-need">
                                Young people need to learn digital skills
                                necessary to thrive in the future world of
                                work. However, too often these skills are not
                                adequately addressed in education systems that
                                are slow to adapt to changing needs.
                              </div>
                            </div>
                            <div className="good-health-well-being-parent">
                              <div className="good-health">
                                Good Health & Well Being
                              </div>
                              <div className="through-marty-the">
                                Through Marty the Robot help teachers deliver
                                learning experiences that are inclusive,
                                supportive and fun.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
