import home from "./pages/home";
import Error from "./pages/page404";

export const allRoutes = [
  {
    path: "/home",
    component: home,
    exact: true,
  }
];
