export default function DiscoverTransform() {
  
  return (
    <>
      <section className="canbiz-landing-page-desktop-inner">
        <div className="frame-parent14">
          <div className="discover-canbiz-transform-yo-wrapper">
            <h1 className="discover-canbiz whiteFonts">
              Discover CanBiz & Transform Your Vision into Reality
            </h1>
          </div>
          <div className="content-parent">
            <div className="content">
              <img
                className="user-friendly-interface"
                loading="lazy"
                alt=""
                src="/img/user-friendly-interface.svg"
              />

              <div className="feature-list">
                <div className="user-friendly-interface1">
                  User-Friendly Interface
                </div>
              </div>
            </div>
            <div className="content1">
              <img
                className="comprehensive-features-icon"
                loading="lazy"
                alt=""
                src="/img/comprehensive-features.svg"
              />

              <div className="comprehensive-features-wrapper">
                <div className="comprehensive-features">
                  Comprehensive Features
                </div>
              </div>
            </div>
            <div className="content2">
              <img
                className="collaborative-platform-icon"
                alt=""
                src="/img/collaborative-platform.svg"
              />

              <div className="collaborative-platform-wrapper">
                <div className="collaborative-platform">
                  Collaborative Platform
                </div>
              </div>
            </div>
            <div className="content3">
              <img
                className="customer-centric-approach-icon"
                loading="lazy"
                alt=""
                src="/img/customercentric-approach.svg"
              />

              <div className="customer-centric-approach-wrapper">
                <div className="customer-centric-approach">
                  Customer-Centric Approach
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-image">
            <div className="dashboard-image-child"></div>
            <div className="dashboard-container">
              <div className="ellipse-group">
                <div className="frame-child4"></div>
                <img
                  className="canbiz-dashboard-grid-view"
                  loading="lazy"
                  alt=""
                  src="/img/canbiz--dashboard--grid-view-1@2x.png"
                />

                <div className="grid-image"></div>
              </div>
            </div>
          </div>
          <div className="entrepreneurship">
            <div className="we-believe-in">
              We believe in the power of entrepreneurship to drive innovation
              and change. Founded with a vision to empower aspiring
              entrepreneurs and businesses of all sizes, CanBiz provides
              intuitive tools and resources to turn ideas into reality. Our
              mission is to democratize entrepreneurship by making essential
              business tools accessible to everyone. Whether you're a seasoned
              entrepreneur or just starting your journey, CanBiz is here to
              support you every step of the way.
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
