export default function CreditPlans() {
  
  return (
    <>
      <section className="credit-content-wrapper">
        <div className="credit-content">
          <div className="credit-heading">
            <h1 className="affordable-flexible whiteFonts">
              Affordable & Flexible Credit Plans for Every Need
            </h1>
          </div>
          <div className="credit-plans">
            <div className="credit-plan">
              <div className="plan-details"></div>
              <div className="credit-purchase">
                <div className="purchase-details">
                  <div className="purchase-button">
                    <div className="wrapper-purchase-icon">
                      <img
                        className="purchase-icon"
                        loading="lazy"
                        alt=""
                        src="/img/frame-38.svg"
                      />
                    </div>
                  </div>
                  <div className="credit-amount">
                    <div className="credit-display">
                      <div className="credit-count">
                        <div className="amount-separator">03</div>
                        <div className="credits1">Credits</div>
                      </div>
                    </div>
                    <div className="add-credit">
                      <b className="add-separator">$5.00</b>
                    </div>
                  </div>
                </div>
                <div className="buy-credit-button">
                  <button className="buy-credit-container">
                    <div className="buy-credits">Buy Credits</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="credit-plan1">
              <div className="credit-info-parent">
                <div className="credit-info">
                  <div className="wrapper-credit-icon">
                    <img
                      className="credit-icon"
                      loading="lazy"
                      alt=""
                      src="/img/frame-39.svg"
                    />
                  </div>
                </div>
                <div className="credit-name">
                  <div className="credit-label">
                    <div className="credit-value">
                      <div className="separator1">05</div>
                      <div className="credits2">Credits</div>
                    </div>
                  </div>
                  <div className="credit-button">
                    <b className="button-separator">$20.00</b>
                  </div>
                </div>
              </div>
              <div className="credit-plan-inner">
                <button className="buy-credits-wrapper">
                  <div className="buy-credits1">Buy Credits</div>
                </button>
              </div>
            </div>
            <div className="credit-plan2">
              <div className="frame-parent15">
                <div className="frame-wrapper5">
                  <div className="frame3">
                    <img
                      className="vector-icon"
                      alt=""
                      src="/img/vector.svg"
                    />

                    <img
                      className="vector-icon1"
                      alt=""
                      src="/img/vector-1.svg"
                    />

                    <img
                      className="vector-icon2"
                      alt=""
                      src="/img/vector-2.svg"
                    />

                    <img
                      className="vector-icon3"
                      alt=""
                      src="/img/vector-3.svg"
                    />

                    <img
                      className="frame-child5"
                      alt=""
                      src="/img/group-1000008974.svg"
                    />

                    <img
                      className="frame-child6"
                      loading="lazy"
                      alt=""
                      src="/img/group-1000008972.svg"
                    />

                    <img
                      className="frame-child7"
                      alt=""
                      src="/img/group-1000008973@2x.png"
                    />
                  </div>
                </div>
                <div className="frame-parent16">
                  <div className="frame-wrapper6">
                    <div className="parent">
                      <div className="div8">10</div>
                      <div className="credits3">Credits</div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <b className="b">$35.00</b>
                  </div>
                </div>
              </div>
              <div className="credit-plan-child">
                <button className="buy-credits-container">
                  <div className="buy-credits2">Buy Credits</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
