
export default function HappyCustomers() {
  
  return (
    <>
      <section className="customer-review">
        <h1 className="hear-from-our whiteFonts">Hear from Our Happy Customers</h1>
        <div className="customer-review-list">
          <div className="review">
            <div className="content4">
              <div className="canbiz-has-been">
                CanBiz has been a game-changer for my startup journey. It's
                incredibly intuitive and helped me map out my business model
                effortlessly.
              </div>
              <div className="information">
                <div className="sarah-h">Sarah H</div>
                <div className="startup-founder">Startup Founder</div>
                <div className="stars">
                  <img
                    className="review-star-icon"
                    alt=""
                    src="/img/frame-40.svg"
                  />

                  <img
                    className="review-star-icon1"
                    alt=""
                    src="/img/frame-41.svg"
                  />

                  <img
                    className="review-star-icon2"
                    alt=""
                    src="/img/frame-42.svg"
                  />

                  <img
                    className="review-star-icon3"
                    alt=""
                    src="/img/frame-43.svg"
                  />

                  <img
                    className="review-star-icon4"
                    alt=""
                    src="/img/frame-44.svg"
                  />
                </div>
              </div>
            </div>
            <img
              className="review-child"
              loading="lazy"
              alt=""
              src="/img/group-1000008968@2x.png"
            />
          </div>
          <div className="review1">
            <div className="content5">
              <div className="canbiz-has-been1">
                CanBiz has been a game-changer for my startup journey. It's
                incredibly intuitive and helped
              </div>
              <div className="information1">
                <div className="stars1">
                  <img
                    className="frame-icon31"
                    alt=""
                    src="/img/frame-45.svg"
                  />

                  <img
                    className="frame-icon32"
                    alt=""
                    src="/img/frame-46.svg"
                  />

                  <img
                    className="frame-icon33"
                    alt=""
                    src="/img/frame-46.svg"
                  />

                  <img
                    className="frame-icon34"
                    alt=""
                    src="/img/frame-46.svg"
                  />

                  <img
                    className="frame-icon35"
                    alt=""
                    src="/img/frame-49.svg"
                  />
                </div>
                <div className="reviewer-details">
                  <div className="michael-t">Michael T</div>
                  <div className="small-business-owner">
                    Small Business Owner
                  </div>
                </div>
              </div>
            </div>
            <img
              className="review-item"
              loading="lazy"
              alt=""
              src="/img/group-1000008968-1@2x.png"
            />
          </div>
          <div className="review2">
            <div className="content6">
              <div className="ive-tried-several">
                I've tried several business model tools, but CanBiz stands out
                for its simplicity and robust features. Highly recommend!
              </div>
              <div className="information2">
                <div className="stars2">
                  <img
                    className="frame-icon36"
                    alt=""
                    src="/img/frame-45.svg"
                  />

                  <img
                    className="frame-icon37"
                    alt=""
                    src="/img/frame-51.svg"
                  />

                  <img
                    className="frame-icon38"
                    alt=""
                    src="/img/frame-52.svg"
                  />

                  <img
                    className="frame-icon39"
                    alt=""
                    src="/img/frame-52.svg"
                  />

                  <img
                    className="frame-icon40"
                    alt=""
                    src="/img/frame-54.svg"
                  />
                </div>
                <div className="emily-s-parent">
                  <div className="emily-s">Emily S</div>
                  <a className="entrepreneur">Entrepreneur</a>
                </div>
              </div>
            </div>
            <img
              className="review-inner"
              loading="lazy"
              alt=""
              src="/img/group-1000008968-1@2x.png"
            />
          </div>
          <div className="review3">
            <div className="content7">
              <div className="canbiz-helped-me">
                CanBiz helped me validate my business idea and refine my
                strategy. The Element Tests were invaluable in understanding
                my market.
              </div>
              <div className="information3">
                <div className="stars3">
                  <img
                    className="frame-icon41"
                    alt=""
                    src="/img/frame-45.svg"
                  />

                  <img
                    className="frame-icon42"
                    alt=""
                    src="/img/frame-46.svg"
                  />

                  <img
                    className="frame-icon43"
                    alt=""
                    src="/img/frame-46.svg"
                  />

                  <img
                    className="frame-icon44"
                    alt=""
                    src="/img/frame-46.svg"
                  />

                  <img
                    className="frame-icon45"
                    alt=""
                    src="/img/frame-49.svg"
                  />
                </div>
                <div className="david-l-parent">
                  <div className="david-l">David L</div>
                  <div className="consultant">Consultant</div>
                </div>
              </div>
            </div>
            <img
              className="review-child1"
              loading="lazy"
              alt=""
              src="/img/group-1000008968-1@2x.png"
            />
          </div>
          <div className="review4">
            <div className="content8">
              <div className="as-a-business">
                As a business consultant, I rely on CanBiz to collaborate with
                clients and visualize their business models. It's a must-have
                tool in my arsenal.
              </div>
              <div className="information4">
                <div className="stars4">
                  <img
                    className="frame-icon46"
                    alt=""
                    src="/img/frame-60.svg"
                  />

                  <img
                    className="frame-icon47"
                    alt=""
                    src="/img/frame-61.svg"
                  />

                  <img
                    className="frame-icon48"
                    alt=""
                    src="/img/frame-61.svg"
                  />

                  <img
                    className="frame-icon49"
                    alt=""
                    src="/img/frame-61.svg"
                  />

                  <img
                    className="frame-icon50"
                    alt=""
                    src="/img/frame-64.svg"
                  />
                </div>
                <div className="jasmine-m-parent">
                  <div className="jasmine-m">Jasmine M</div>
                  <div className="marketing-professional">
                    Marketing Professional
                  </div>
                </div>
              </div>
            </div>
            <img
              className="review-child2"
              loading="lazy"
              alt=""
              src="/img/group-1000008968-1@2x.png"
            />
          </div>
          <div className="review5">
            <div className="content9">
              <div className="even-as-a">
                Even as a student entrepreneur, CanBiz has been instrumental
                in shaping my business ideas. The user-friendly interface
                makes it accessible for beginners like me.
              </div>
              <div className="information5">
                <div className="stars5">
                  <img
                    className="frame-icon51"
                    alt=""
                    src="/img/frame-60.svg"
                  />

                  <img
                    className="frame-icon52"
                    alt=""
                    src="/img/frame-61.svg"
                  />

                  <img
                    className="frame-icon53"
                    alt=""
                    src="/img/frame-61.svg"
                  />

                  <img
                    className="frame-icon54"
                    alt=""
                    src="/img/frame-61.svg"
                  />

                  <img
                    className="frame-icon55"
                    alt=""
                    src="/img/frame-64.svg"
                  />
                </div>
                <div className="alex-b-parent">
                  <div className="alex-b">Alex B</div>
                  <a className="student-entrepreneur">Student Entrepreneur</a>
                </div>
              </div>
            </div>
            <img
              className="review-child3"
              loading="lazy"
              alt=""
              src="/img/group-1000008968-1@2x.png"
            />
          </div>
          <div className="review-item1"></div>
          <div className="review-item2"></div>
        </div>
      </section>
    </>
  );
}
