// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { config } from "../config";
import Header from "../components/Header";
import "../styles/global.css";
import "../styles/index.css";

import BusinessModelCanvas from "../components/Businessmodelcanvas";
import BusinessGrowth from "../components/Businessgrowth";
import DiscoverTransform from "../components/Discovertransform";
import CreditPlans from "../components/Creditplans";
import HappyCustomers from "../components/Happycustomers";
import { Seo } from "../utilities/Seo";
const Home = () => {
  return (
    <>
     <Seo/>
      <div className="canbiz-landing-page-desktop">
        <div className="hero-image"></div>
        
        <Header />
        
        {/* Canbiz - A Business Model Canvas Portal */}
        <BusinessModelCanvas />

        {/* Powerful Features to Fuel Your Business Growth */}
        <BusinessGrowth />

        {/* Discover CanBiz & Transform Your Vision into Reality */}
        <DiscoverTransform />

        {/* Affordable & Flexible Credit Plans for Every Need */}
        <CreditPlans />
        
        {/* Hear from Our Happy Customers */}
        <HappyCustomers />
        
        
        <section className="container">
          <div className="footer-top">
            <div className="footer-content">
              <img
                className="footer-content-child"
                loading="lazy"
                alt=""
                src="/img/group-1000008967.svg"
              />

              <div className="footer-ellipsis"></div>
            </div>
            <div className="footer-bottom">
              <div className="success-container">
                <div className="success-message">
                  <h1 className="start-building-your whiteFonts">
                    Start Building Your Success Story Today
                  </h1>
                  <div className="take-the-first">
                    Take the first step towards entrepreneurial success. Sign up
                    now to unlock powerful tools and resources with CanBiz.
                  </div>
                </div>
                <button className="footer-button">
                  <div className="get-started8">Get Started</div>
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer className="header1">
          <div className="header-child"></div>
          <div className="frame-parent17">
            <div className="canbiz-all-rights-reserved-wrapper">
              <div className="canbiz-all-rights">
                © 2024 Canbiz. All rights reserved.
              </div>
            </div>
            <div className="button-container">
              <img
                className="demo-button-icon"
                loading="lazy"
                alt=""
                src="/img/frame-70.svg"
              />

              <img
                className="login-button-icon"
                loading="lazy"
                alt=""
                src="/img/frame-71.svg"
              />

              <img
                className="empty-button-icon"
                loading="lazy"
                alt=""
                src="/img/frame-72.svg"
              />
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
