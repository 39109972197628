export default function BusinessGrowth() {
  
  return (
    <>
      <section className="features-content-wrapper">
        <div className="features-content">
          <div className="features-title">
            <h1 className="powerful-features-to whiteFonts">
              Powerful Features to Fuel Your Business Growth
            </h1>
          </div>
          <div className="canvas-creation">
            <div className="target-customer">
              <div className="customer-name-input">
                <div className="input-suggestions">
                  <div className="ellipse-parent">
                    <div className="ellipse-div"></div>
                    <img
                      className="name-input-border"
                      alt=""
                      src="/img/name-input-border@2x.png"
                    />
                  </div>
                  <div className="step-11">
                    <div className="input-group">
                      <div className="name-of-your">
                        Name of your target Customer Segment?
                      </div>
                      <div className="input-placeholder">
                        <input
                          className="enter-customer-segment"
                          placeholder="Enter customer segment name"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="ai-suggestions">
                      <div className="suggestion-header">
                        <div className="ai-suggestions1">AI Suggestions</div>
                        <img
                          className="suggestion-dropdown-icon"
                          alt=""
                          src="/img/frame-19.svg"
                        />
                      </div>
                      <div className="suggestion-list">
                        <div className="element-type">
                          <div className="secondary-schools">
                            Secondary schools
                          </div>
                        </div>
                        <div className="ethical-commuters-wrapper">
                          <div className="ethical-commuters">
                            Ethical Commuters
                          </div>
                        </div>
                        <div className="tech-savvy-young-women-wrapper">
                          <div className="tech-savvy-young">
                            Tech savvy young women
                          </div>
                        </div>
                        <div className="independent-high-street-retail-wrapper">
                          <div className="independent-high-street-retail">
                            Independent high-street retailers
                          </div>
                        </div>
                        <div className="busy-health-conscious-parents-wrapper">
                          <div className="busy-health-conscious">
                            Busy, health conscious parents
                          </div>
                        </div>
                        <div className="women-with-afro-hair-wrapper">
                          <div className="women-with-afro">
                            Women with afro hair
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="action">
                      <div className="load-more-suggestions">
                        Load more suggestions
                      </div>
                      <button className="next-button-container">
                        <div className="next">Next</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="canvas-benefits">
                  <h2 className="create-your-business whiteFonts">
                    Create your Business Model Canvas
                  </h2>
                  <div className="seamlessly-design-iterate">
                    Seamlessly design, iterate, and refine your business
                    strategy to unlock opportunities and drive growth.
                  </div>
                  <div className="get-started-parent">
                    <input
                      className="get-started1"
                      placeholder="Get Started"
                      type="text"
                    />

                    <div className="frame-child1"></div>
                  </div>
                </div>
              </div>
              <div className="customer-name-input1">
                <div className="wrapper-group-1000008965-parent">
                  <div className="wrapper-group-1000008965">
                    <img
                      className="wrapper-group-1000008965-child"
                      alt=""
                      src="/img/group-1000008965@2x.png"
                    />
                  </div>
                  <div className="popup-create-test">
                    <div className="frame-parent13">
                      <div className="create-test-parent">
                        <div className="create-test">Create Test</div>
                        <div className="this-test-has-container">
                          <span>This Test has utilize </span>
                          <span className="credits">5 credits</span>
                          <span>.</span>
                        </div>
                      </div>
                      <img
                        className="frame-icon18"
                        alt=""
                        src="/img/frame-20.svg"
                      />
                    </div>
                    <div className="element-selection-parent">
                      <div className="element-selection">
                        <div className="select-business-model">
                          Select business model canvas
                        </div>
                        <div className="frame-frame">
                          <img
                            className="frame-icon19"
                            alt=""
                            src="/img/frame-21.svg"
                          />
                        </div>
                      </div>
                      <div className="element-selection1">
                        <div className="select-element-type">
                          Select element type
                        </div>
                        <div className="frame-wrapper4">
                          <img
                            className="frame-icon20"
                            alt=""
                            src="/img/frame-22.svg"
                          />
                        </div>
                      </div>
                      <div className="element-selection2">
                        <div className="select-element">Select element</div>
                        <div className="element-dropdown">
                          <img
                            className="frame-icon21"
                            alt=""
                            src="/img/frame-23.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="create-test-on-surveymenkeyco-wrapper">
                      <div className="create-test-on-container">
                        Create Test on
                        <span className="surveymenkeycom">
                          surveymenkey.com
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="validate-your-business-bluepri-parent">
                  <h2 className="validate-your-business whiteFonts">
                    Validate Your Business Blueprint
                  </h2>
                  <div className="validate-your-business1">
                    Validate your business model with our Element Test
                    feature. Gain valuable insights, test assumptions, and
                    refine your strategy.
                  </div>
                  <div className="get-started-group">
                    <input
                      className="get-started2"
                      placeholder="Get Started"
                      type="text"
                    />

                    <div className="frame-child2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="third-party-integration">
              <div className="integration-content">
                <h2 className="enhance-your-journey whiteFonts">
                  Enhance Your Journey with Exclusive Third-Party Resources
                </h2>
                <div className="integration-benefits">
                  <div className="benefit-list">
                    <div className="benefit-item">
                      <input
                        className="benefit-item-child"
                        type="radio"
                        name="radioGroup-1"
                      />
                    </div>
                    <div className="access-a-wide">
                      Access a wide range of industry-leading software
                      integrations.
                    </div>
                  </div>
                  <div className="benefit-list1">
                    <div className="frame">
                      <input
                        className="group-input"
                        type="radio"
                        name="radioGroup-1"
                      />
                    </div>
                    <div className="expand-your-network">
                      Expand your network and discover new opportunities
                      through integrated networking platforms.
                    </div>
                  </div>
                  <div className="benefit-list2">
                    <div className="frame1">
                      <input className="frame-child3" type="radio" />
                    </div>
                    <div className="access-premium-content">
                      Access premium content and educational resources through
                      integrated platforms.
                    </div>
                  </div>
                </div>
                <div className="integration-action">
                  <input
                    className="get-started3"
                    placeholder="Get Started"
                    type="text"
                  />

                  <div className="integration-divider"></div>
                </div>
              </div>
              <div className="branding-content-wrapper">
                <div className="branding-content">
                  <div className="branding-list">
                    <div className="enhance-your-journey1">
                      Enhance Your Journey with Exclusive Third-Party
                      Resources
                    </div>
                    <div className="list10">
                      <div className="tool-list">
                        <img
                          className="tool-item-icon"
                          loading="lazy"
                          alt=""
                          src="/img/frame-24.svg"
                        />

                        <div className="tool-description">
                          <div className="brand-your-business">
                            Brand Your Business
                          </div>
                          <div className="tools-that-support">
                            Tools that support your growth
                          </div>
                        </div>
                        <img
                          className="tool-icon"
                          alt=""
                          src="/img/frame-25.svg"
                        />
                      </div>
                      <div className="tool-list1">
                        <img
                          className="frame-icon22"
                          loading="lazy"
                          alt=""
                          src="/img/frame-26.svg"
                        />

                        <div className="survey-testing-parent">
                          <div className="survey-testing">
                            Survey & Testing
                          </div>
                          <div className="ab-testing-manual">
                            AB testing, Manual Testing, User testing
                          </div>
                        </div>
                        <img
                          className="frame-icon23"
                          alt=""
                          src="/img/frame-27.svg"
                        />
                      </div>
                      <div className="tool-list2">
                        <img
                          className="frame-icon24"
                          alt=""
                          src="/img/frame-28.svg"
                        />

                        <div className="marketing-parent">
                          <div className="marketing">Marketing</div>
                          <input
                            className="social-growth-b2b"
                            placeholder="Social growth, B2B, B2C, SEO"
                            type="text"
                          />
                        </div>
                        <img
                          className="frame-icon25"
                          alt=""
                          src="/img/frame-29.svg"
                        />
                      </div>
                      <div className="tool-list3">
                        <input className="frame2" type="checkbox" />

                        <div className="web-app-development-parent">
                          <div className="web-app">Web & App Development</div>
                          <div className="website-mobile-application">
                            Website, mobile application for bussiness
                          </div>
                        </div>
                        <img
                          className="frame-icon26"
                          alt=""
                          src="/img/frame-31.svg"
                        />
                      </div>
                      <div className="tool-list4">
                        <img
                          className="frame-icon27"
                          alt=""
                          src="/img/frame-32.svg"
                        />

                        <div className="manage-database-parent">
                          <div className="manage-database">
                            Manage Database
                          </div>
                          <input
                            className="server-maintainence-tech"
                            placeholder="Server maintainence, Tech support"
                            type="text"
                          />
                        </div>
                        <img
                          className="frame-icon28"
                          alt=""
                          src="/img/frame-33.svg"
                        />
                      </div>
                      <div className="tool-list5">
                        <img
                          className="frame-icon29"
                          loading="lazy"
                          alt=""
                          src="/img/frame-34.svg"
                        />

                        <div className="funding-opportunities-parent">
                          <div className="funding-opportunities">
                            Funding Opportunities
                          </div>
                          <div className="business-may-be">
                            Business may be eligible for funding
                          </div>
                        </div>
                        <img
                          className="frame-icon30"
                          alt=""
                          src="/img/frame-35.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="branding-popup">
                <div className="popup-trigger"></div>
                <div className="popup">
                  <div className="popup-content">
                    <div className="brand-your-business1">
                      Brand Your Business
                    </div>
                    <div className="brand-container">
                      <img
                        className="brand-icon"
                        alt=""
                        src="/img/frame-36.svg"
                      />
                    </div>
                  </div>
                  <div className="sportwear-container">
                    <div className="sportwear-label">
                      <a className="sporst-wear">Sporst Wear</a>
                      <img
                        className="sportwear-icon"
                        alt=""
                        src="/img/frame-37.svg"
                      />
                    </div>
                    <div className="tools-container">
                      <div className="tools-brand">
                        <div className="tools-list">
                          <div className="tool">
                            <img
                              className="figma-symbolsvg-icon"
                              alt=""
                              src="/img/figma-symbolsvg.svg"
                            />

                            <img
                              className="figma-logosvg-icon"
                              loading="lazy"
                              alt=""
                              src="/img/figma-logosvg.svg"
                            />
                          </div>
                          <div className="figma-is-a">
                            Figma is a design platform built for
                            collaboration. It lets you and your team work on
                            designs simultaneously, in real-time. With its
                            intuitive interface, you can transform your design
                            ideas into reality and ensure everyone is on the
                            same page.
                          </div>
                          <div className="get-started4">Get Started</div>
                        </div>
                        <div className="tools-list1">
                          <img
                            className="group-icon"
                            loading="lazy"
                            alt=""
                            src="/img/group.svg"
                          />

                          <div className="fiverr-is-global">
                            Fiverr is global online marketplace offering tasks
                            and services, beginning at a cost of $5 per job.
                            It connects businesses with freelancers offering
                            digital services in categories like graphics &
                            design, marketing, writing, programming, and more.
                          </div>
                          <div className="get-started5">Get Started</div>
                        </div>
                        <div className="tools-list2">
                          <div className="framer-logosvg">
                            <img
                              className="group-icon1"
                              loading="lazy"
                              alt=""
                              src="/img/group-1.svg"
                            />

                            <div className="group-wrapper">
                              <img
                                className="group-icon2"
                                loading="lazy"
                                alt=""
                                src="/img/group-2.svg"
                              />
                            </div>
                          </div>
                          <div className="a-free-tool">
                            A free tool for UI and UX design Framer is a free
                            platform that allows you to create realistic UI
                            and UX designs, for any type of website, that are
                            interactive from the start.
                          </div>
                          <div className="get-started6">Get Started</div>
                        </div>
                        <div className="tools-list3">
                          <img
                            className="webflow-logosvg-icon"
                            loading="lazy"
                            alt=""
                            src="/img/webflow-logosvg.svg"
                          />

                          <div className="create-custom-responsive">
                            Create custom, responsive websites with the power
                            of code — visually. Design and build your site
                            with a flexible CMS and top-tier hosting.
                          </div>
                          <div className="get-started7">Get Started</div>
                        </div>
                      </div>
                      <div className="tools-divider"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
